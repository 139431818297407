<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <span class="display-4 col-12">{{ product.name }}</span>
    </div>

    <div class="row">
      <div class="col-9">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
          <dd class="col-sm-8">
            {{ product.name }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATUS") }}</dt>
          <dd class="col-sm-8">
            <div class="d-flex">
              <product-status-badge :product="product" />
              <product-novelty-badge :product="product" />
            </div>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("PRODUCTS.SKU") }}</dt>
          <dd class="col-sm-8">
            {{ product.sku }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UNIT_OF_MEASURE") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="product.unitOfMeasure" />
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EXCERPT") }}</dt>
          <dd class="col-sm-8">
            <div v-html="product.excerpt"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PRODUCTS.CAN_BE_SOLD") }}</dt>
          <dd class="col-sm-8">
            <icon-check :checked="product.can_be_sold" />
          </dd>
        </dl>
        <dl class="row" v-if="product.can_be_sold">
          <dt class="col-sm-4">{{ $t("COMMON.SELLING_PRICE") }}</dt>
          <dd class="col-sm-8">
            {{ $formatCurrency(product.selling_price) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PRODUCTS.CAN_BE_PURCHASED") }}</dt>
          <dd class="col-sm-8">
            <icon-check :checked="product.can_be_purchased" />
          </dd>
        </dl>
        <dl class="row" v-if="product.can_be_purchased">
          <dt class="col-sm-4">{{ $t("COMMON.BUYING_PRICE") }}</dt>
          <dd class="col-sm-8">
            {{ $formatCurrency(product.buying_price) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.DEFAULT_TAX_GROUPS") }}</dt>
          <dd class="col-sm-8">
            <tax-groups :taxGroups="product.taxGroups" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CATEGORIES") }}</dt>
          <dd class="col-sm-8">
            <category
              v-for="(category, key) in product.categories"
              :key="key"
              :category="category"
            />
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd class="col-sm-8">
            <locations :locations="product.allowedLocations" />
          </dd>
        </dl>
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="product.organization" />
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="product.created_at">
            {{ $formatDate(product.created_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="product.updated_at">
            {{ $formatDate(product.updated_at) }}
          </dd>
        </dl>
      </div>
      <div class="col-3">
        <img
          v-if="product.picture"
          :src="`${product.picture}`"
          class="argon-image mb-2"
          style="width: 100%"
        />
        <img v-else src="/img/placeholder.jpg" class="argon-image mb-2" />
      </div>
    </div>
  </div>
</template>

<script>
import ProductStatusBadge from "@/components/ProductStatusBadge";
import ProductNoveltyBadge from "@/components/ProductNoveltyBadge";
import Category from "@/components/CategoryLabel";
import TaxGroups from "@/components/TaxGroups.vue";
import IconCheck from "@/components/IconCheck.vue";

export default {
  name: "product-view-global",

  components: {
    Category,
    ProductStatusBadge,
    ProductNoveltyBadge,
    TaxGroups,
    IconCheck,
  },

  props: ["product"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    product(product) {},
  },
};
</script>
